/**
 * @format
 */
import React from 'react';
import {Badge, Card, Space, Typography} from 'antd';

const {Title, Paragraph, Text} = Typography;

type Props = Readonly<{
  isPixelAvailable: boolean;
  isAccessTokenAvailable: boolean;
}>;

export function DisconnectedStatusCard(props: Props) {
  const {isPixelAvailable, isAccessTokenAvailable} = props;
  return (
    <Card>
      <Title level={5}>No Pixel Connection</Title>
      <Paragraph>
        This instance is not yet connected to a Meta Pixel. Please consult the
        Conversions API Gateway documentation for connection instructions.
      </Paragraph>
      <br />
      <Space direction="vertical">
        <Text strong>Details:</Text>
        <div>
          <Badge status={isPixelAvailable ? 'success' : 'default'} />
          <Text>
            {isPixelAvailable
              ? 'Pixel ID is configured'
              : 'Pixel ID is not configured'}{' '}
          </Text>
        </div>
        <div>
          <Badge status={isAccessTokenAvailable ? 'success' : 'default'} />
          <Text>
            {isAccessTokenAvailable
              ? 'Access Token is configured'
              : 'Access Token is not configured'}{' '}
          </Text>
        </div>
      </Space>
    </Card>
  );
}
