/**
 * @format
 */

const APP_ID = '533664028219413';
const DEFAULT_TIME_ZONE = 'America/Los_Angeles';

const FBE_URL = new URL(
  'https://business.facebook.com/fbe-iframe-get-started/',
);

const FBE_BUSINESS_VERTICAL = 'MEASUREMENT_AND_OPTIMIZATION';
const CURRENCY = 'USD';
const INSTALLED = '0';
export const VERSION = 'v14.0';

export function fbeURLBuilder(
  externalId: string,
  channel: string,
  business_name: string,
): string {
  FBE_URL.searchParams.append('app_id', APP_ID);
  FBE_URL.searchParams.append('timezone', DEFAULT_TIME_ZONE);
  FBE_URL.searchParams.append('external_business_id', externalId);
  FBE_URL.searchParams.append('channel', channel);
  FBE_URL.searchParams.append('business_vertical', FBE_BUSINESS_VERTICAL);
  FBE_URL.searchParams.append('currency', CURRENCY);
  FBE_URL.searchParams.append('installed', INSTALLED);
  FBE_URL.searchParams.append('business_name', business_name);
  FBE_URL.searchParams.append('version', VERSION);

  return FBE_URL.toString();
}
