/**
 * Adds a value to head at path if value is not null or undefined.
 * If value already existed at path, turn it into array and concat.
 *
 * Original Source in:  SignalsValidationUtils.js
 */
export function addValueAtPath(
  head: Record<string, any>,
  path: string,
  value: any,
): Record<string, any> {
  const stack = path.split('.');

  if (value === undefined) {
    value = null;
  }
  const stackLength = stack.length;
  for (let _index = 0; _index < stackLength - 1; _index++) {
    const parentKey = stack[_index];
    head = head[parentKey] || (head[parentKey] = {});
  }
  const leafKey = stack[stackLength - 1];

  if (!hasProp(head, leafKey)) {
    head[leafKey] = value;
  } else {
    const currentValue = head[leafKey];

    if (Array.isArray(currentValue)) {
      currentValue.push(value);
    } else {
      head[leafKey] = [currentValue, value];
    }
  }

  return head;
}

function hasProp(obj: Record<string, any>, key: string): boolean {
  return (
    typeof obj === 'object' && Object.prototype.hasOwnProperty.call(obj, key)
  );
}
