/**
 * @format
 */

import {
  Alert,
  Button,
  Card,
  Modal,
  Radio,
  RadioChangeEvent,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import {useEffect, useState} from 'react';
import {InfoCircleFilled, InfoCircleOutlined} from '@ant-design/icons';
import DraggerComponent from './DraggerComponent';
// create-react-app not allow me to refering files outside of "src" folder.
// @ts-ignore
import sampleCsv from './resources/batch_events_samples.csv';
import styles from './styles/BatchUploadModal.module.less';

const {Text, Link} = Typography;

interface Props {
  onClose: () => void;
  visible: boolean;
  dataSourceId: string | null;
  isPLClient: boolean | undefined;
}

/**
 * This method make a raw grahql call to hub's graphql endpoint.
 * TODO: We need to remove this after PC Batch M2 finish.
 */
export function checkIsPLClient(handleResult: (result: boolean) => void) {
  const solutionsQuery = `query SolutionsQuery {
    routes {
      solutions {
        solutionCode
      }
    }
  }
  `;
  const queryBody = {
    query: solutionsQuery,
  };
  return fetch('/hub/graphql/', {
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
    },
    body: JSON.stringify(queryBody),
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((res: {data: SolutionQuery}) => res.data)
    .then((data: SolutionQuery) => {
      const isPcClient = data.routes.solutions.some(
        (sol) => sol.solutionCode === 'PCS',
      );
      handleResult(isPcClient);
    });
}

function checkCanPcClientUpload() {
  const checkKinesisQuery = `query CheckKinesisQuery {
    checkKinesis {
      isKinesisConfigured
      isDescribeStreamSuccessful
      isDynamicPartitioningEnabled
    }
  }
  `;
  const queryBody = {
    query: checkKinesisQuery,
  };
  return fetch('/pcs/graphql/', {
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
    },
    body: JSON.stringify(queryBody),
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
  }).then((res) => res.json());
}

export type SolutionQuery = {
  routes: {
    solutions: Array<{solutionCode: string}>;
  };
};

export function BatchUploadModal({onClose, visible, dataSourceId}: Props) {
  const [processedRow, setProcessedRow] = useState(0);
  const [removedRow, setRemovedRow] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [isPcClient, setIsPcClient] = useState(false);
  const [pcOnlyDataSet, setPCOnlyDataSet] = useState(false);
  const [radioButtonChecked, setRadioButtonChecked] = useState(false);
  const [isKinesisConfigured, setIsKinesisConfigured] = useState(false);
  const [isDescribeStreamSuccessful, setIsDescribeStreamSuccessful] =
    useState(false);
  const [isDynamicPartitioningEnabled, setIsDynamicPartitioningEnabled] =
    useState(false);
  useEffect(() => {
    checkIsPLClient((isPc) => {
      setIsPcClient(isPc);
    });
  }, []);
  //Pc specific logic.
  useEffect(() => {
    if (isPcClient) {
      checkCanPcClientUpload().then((res) => {
        if (res?.data) {
          setIsKinesisConfigured(res.data.checkKinesis.isKinesisConfigured);
          setIsDescribeStreamSuccessful(
            res.data.checkKinesis.isDescribeStreamSuccessful,
          );
          setIsDynamicPartitioningEnabled(
            res.data.checkKinesis.isDynamicPartitioningEnabled,
          );
        }
      });
    }
  }, [isPcClient]);

  function onProgress(data: {success: number; error: number}) {
    setProcessedRow((val) => val + data.success);
    setRemovedRow((val) => val + data.error);
  }

  function onSelectionChange(e: RadioChangeEvent) {
    setPCOnlyDataSet(e.target.value === 2);
    setRadioButtonChecked(true);
  }

  const showKinesisError =
    isPcClient &&
    isKinesisConfigured &&
    isDescribeStreamSuccessful &&
    !isDynamicPartitioningEnabled;
  const showDescribeStreamFailedWarning =
    isPcClient && isKinesisConfigured && !isDescribeStreamSuccessful;
  const showKinesisNotConfiguredWarning = isPcClient && !isKinesisConfigured;
  const hasErrorOrWarningForPC =
    showKinesisError ||
    showDescribeStreamFailedWarning ||
    showKinesisNotConfiguredWarning;

  return (
    <Modal
      title={'Upload event data'}
      visible={visible}
      centered={true}
      maskClosable={false}
      afterClose={() => {
        setProcessedRow(0);
        setRemovedRow(0);
        setRadioButtonChecked(false);
      }}
      cancelButtonProps={{style: {display: 'none'}}}
      width={660}
      closable={false}
      onCancel={onClose}
      destroyOnClose={true}
      footer={[
        <Button disabled={processing} type="primary" onClick={onClose}>
          {'Close'}
        </Button>,
      ]}
    >
      {
        <>
          <p>
            <Text>
              Add event data to <b>Pixel ID {dataSourceId}</b> by uploading a
              CSV file. Event data formatting must correspond the flatten csv of{' '}
              <Link
                target="_blank"
                href="https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/server-event"
              >
                Meta's Server Event Parameter
              </Link>
              . You can use this{' '}
              <Link href={sampleCsv} target="_blank" download>
                sample file
              </Link>{' '}
              as a template to format your data correctly. Data that is
              formatted incorrectly will be removed automatically.
            </Text>
          </p>
          <p>
            {showKinesisError ? (
              <Alert
                message="Your Private Computation data stream setup requires updates.
                        To continue, the Kinesis firehose must use dynamic partitioning.
                        Redeploy your Private Computation infrastructure with the most
                        recent version, or use the semi-automated pipeline. Contact your Meta
                        representatives for more information if needed."
                type="error"
                showIcon={true}
              />
            ) : null}
            {showDescribeStreamFailedWarning ? (
              <Alert
                message="Failed to fetch the Kinesis stream status. This issue may result
                        in uploaded events being dropped before reaching the Private Computation pipeline.
                        Contact your Meta representatives for more information if needed."
                type="error"
                showIcon={true}
              />
            ) : null}
            {showKinesisNotConfiguredWarning ? (
              <Alert
                message="Kinesis has not been configured yet. Please reload the page to refresh the Kinesis state.
                        If the issue persists after reloading the page, install
                        the Private Computation infrastructure from the 'Private Computation Solutions'
                        Deployment page. Contact your Meta representatives for more information if needed."
                type="error"
                showIcon={true}
              />
            ) : null}
            {isPcClient && !hasErrorOrWarningForPC ? (
              <Radio.Group onChange={onSelectionChange}>
                <Space direction="vertical">
                  <Radio value={1} className={styles.radio}>
                    Upload event data for Conversions API Gateway and Private
                    Computation
                  </Radio>
                  <Radio value={2} className={styles.radio}>
                    <>
                      <Text>
                        Upload event data for Private Computation use only{' '}
                      </Text>
                      <Tooltip
                        placement="top"
                        title="Choose this option if you only plan to use the event data for Private Computation."
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </>
                  </Radio>
                </Space>
              </Radio.Group>
            ) : null}
          </p>
          <DraggerComponent
            dataSourceId={dataSourceId!}
            onProgress={onProgress}
            onProcessing={(p) => setProcessing(p)}
            dataType={isPcClient && pcOnlyDataSet ? 'PC_UPLOAD' : 'UPLOAD'}
            disabled={
              isPcClient && (!radioButtonChecked || hasErrorOrWarningForPC)
            }
          />

          <Space className={styles.space}>
            <Card className={styles.card}>
              <Text strong>Uploaded events </Text>
              <Tooltip title="The total number of events from your file that were included in your upload and will now appear with this Pixel’s data.">
                <InfoCircleFilled className={styles.anticon} />
              </Tooltip>
              <br />
              <Text type="secondary">{processedRow}</Text>
            </Card>
            <Card className={styles.card}>
              <Text strong>Excluded events </Text>
              <Tooltip title="The total number of events from your file that were excluded from your upload and will not appear with this Pixel’s data.">
                <InfoCircleFilled className={styles.anticon} />
              </Tooltip>
              <br />
              <Text type="secondary">{removedRow}</Text>
            </Card>
          </Space>
        </>
      }
    </Modal>
  );
}
