/**
 * @format
 */
import React from 'react';
import {Typography} from 'antd';
import styles from './RecommendedTextView.module.less';
const {Text} = Typography;

export const RecommendedTextView = () => {
  return (
    <div>
      <Text className={styles.infoText}>RECOMMENDED</Text>
    </div>
  );
};
