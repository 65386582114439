/**
 * @format
 */
import * as queryString from 'querystring';

export const getTenantIdInIframe = (): string => {
  const params = queryString.parse(window.location.search.slice(1));
  const tenantId = params.tenant;


  if (tenantId == null) {
    console.warn('Account ID is not provided');
    return '';
  }
  if (Array.isArray(tenantId)) {
    throw 'Account ID  is not unique.';
  }

  return tenantId;
 };
