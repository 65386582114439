import {
  Typography,
  Modal,
  Card,
  Space,
  Tooltip,
  Switch,
  Input,
  Button,
  Row,
  Col,
  notification,
} from 'antd';
import {ReloadOutlined} from '@ant-design/icons';
import styles from './styles/accessKeyModal.module.less';

const {Text, Paragraph} = Typography;

interface Props {
  onClose: () => void;
  onRefresh(): void;
  visible: boolean;
  dataSourceId: string | null;
  accessKeyPass: string | null;
}

export function AccessKeyModal({
  onClose,
  onRefresh,
  visible,
  dataSourceId,
  accessKeyPass,
}: Props) {
  const copykey = () => {
    navigator.clipboard.writeText(accessKeyPass);
  };

  return (
    <Modal
      title={'AccessKey Status'}
      okText="Done"
      visible={visible}
      centered={true}
      width={660}
      onCancel={onClose}
      onOk={onClose}
    >
      <Space direction="vertical">
        <Row>
          <Col span={18}>
            <Input value={accessKeyPass} disabled />
          </Col>
          <Col span={2} style={{paddingLeft: 4}}>
            <Button
              shape="default"
              icon={<ReloadOutlined />}
              onClick={onRefresh}
            ></Button>
          </Col>
          <Col span={2} offset={2}>
            <Button onClick={copykey}> Copy Key</Button>
          </Col>
        </Row>
      </Space>
    </Modal>
  );
}
