import creatProcessMultiPipe from './BatchProcessor';
import type {ProcessMessage} from './types/ProcessMessage';
import {
  UploadRequestOption,
  UploadRequestError,
  UploadProgressEvent,
  RcFile, // Extended File with an uid
} from 'rc-upload/lib/interface';
import {CloudUploadOutlined} from '@ant-design/icons';
import {Upload, UploadProps, message, Typography} from 'antd';
import {useEffect, useState} from 'react';
import styles from './styles/DraggerComponent.module.less';
const {Dragger} = Upload;
const {Text} = Typography;

type ProgressData = {success: number; error: number; percent: number};

interface DraggerProp {
  onProgress: (data: ProgressData) => void;
  dataSourceId: string;
  onProcessing?: (p: boolean) => void;
  dataType?: string;
  disabled: boolean;
}

const uploadProps: UploadProps = {
  name: 'file',
  multiple: true,
  accept: '.csv',
  onRemove(file) {
    if (file.status === 'uploading') {
      multiFileHandle.abort(file.uid);
    }
  },
};

const multiFileHandle = creatProcessMultiPipe();

export default function DraggerComponent({
  onProgress,
  dataSourceId,
  dataType,
  onProcessing = (p: boolean) => {},
  disabled,
}: DraggerProp) {
  const [processing, setProcessing] = useState(0);
  useEffect(() => {
    onProcessing(processing !== 0);
  }, [processing, onProcessing]);

  function customRequest(option: UploadRequestOption) {
    const rcFile = option.file as RcFile;
    const onMessage = (dataMessage: ProcessMessage) => {
      switch (dataMessage.type) {
        case 'completed':
          setProcessing((val) => val - 1);
          message.success(`${rcFile.name} processed successfully.`);
          option.onSuccess?.call(undefined, null);
          break;
        case 'error':
          setProcessing((val) => val - 1);
          const {msg} = dataMessage.data as {msg: string};
          message.error(`${rcFile.name} processe failed: ${msg}`);
          option.onError?.call(undefined, {message: msg} as UploadRequestError);
          break;
        case 'progress':
          const data = dataMessage.data as ProgressData;
          option.onProgress?.call(undefined, {
            percent: data.percent,
          } as UploadProgressEvent);
          onProgress(data);
          break;
      }
    };
    setProcessing((val) => val + 1);
    multiFileHandle.submit({
      file: rcFile,
      taskId: rcFile.uid,
      msgHandle: onMessage,
      options: {
        dataSourceId: dataSourceId,
        dataType: dataType,
      },
    });
  }

  return (
    <Dragger
      // TODO: add className={styles.upload} figure out a way to override AntD components
      {...uploadProps}
      customRequest={customRequest}
      style={{
        width: 608,
      }}
      disabled={disabled}
    >
      <p className="ant-upload-drag-icon">
        <CloudUploadOutlined
          className={styles.CloudUploadOutlined}
          style={disabled ? {color: 'grey'} : {}}
        />{' '}
        :
      </p>
      <p
        className="ant-upload-text"
        //TODO: figure out a way to override these inline css for class: "ant-upload-text", "ant-upload-hint"
        style={{
          marginTop: -10,
        }}
      >
        <Text>Drag file here or click to upload</Text>
      </p>
      <p
        className="ant-upload-hint"
        style={{
          marginBottom: 10,
        }}
      >
        <Text type="secondary">
          File upload may take over 10mins. Actual time will vary depending on
          file size and available resources. Incompatible data will be removed
          automatically.
        </Text>
      </p>
    </Dragger>
  );
}
