import React from 'react';
import {Select} from 'antd';

const {Option} = Select;

export const timeWindowOptions = [
    <Option value={'30d'} key={0}>
        Last 30 Days
    </Option>,
    <Option value={'7d'} key={1}>
        Last 7 Days
    </Option>,
    <Option value={'24h'} key={2}>
        Last 24 Hours
    </Option>,
    <Option value={'1h'} key={3}>
        Last Hour
    </Option>,
];
