/**
 * @format
 */
import React from 'react';

type Props = React.PropsWithChildren<{
  fallback: (error: Error) => React.ReactNode;
}>;

type State = {
  error: Error | undefined;
};

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {error: undefined};
  }

  static getDerivedStateFromError(error: Error) {
    return {error};
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  render() {
    if (this.state.error !== undefined) {
      return this.props.fallback(this.state.error);
    } else {
      return this.props.children;
    }
  }
}
