/**
 * @format
 */
import {useEffect, useState} from 'react';
import {ReactComponent as AddPaymentInfoIcon} from '../assets/event-type-icons/AddPaymentInfoEvent.svg';
import {ReactComponent as AddToCartIcon} from '../assets/event-type-icons/AddToCartEvent.svg';
import {ReactComponent as AddToWishlistIcon} from '../assets/event-type-icons/AddToWishlistEvent.svg';
import {ReactComponent as CompleteRegistrationIcon} from '../assets/event-type-icons/CompleteRegistrationEvent.svg';
import {ReactComponent as ContactIcon} from '../assets/event-type-icons/ContactEvent.svg';
import {ReactComponent as FindLocationIcon} from '../assets/event-type-icons/FindLocationEvent.svg';
import {ReactComponent as InitiateCheckoutIcon} from '../assets/event-type-icons/InitiateCheckoutEvent.svg';
import {ReactComponent as PurchaseIcon} from '../assets/event-type-icons/PurchaseEvent.svg';
import {ReactComponent as UnknownEventIcon} from '../assets/event-type-icons/UnknownEvent.svg';
import {ReactComponent as ScheduleIcon} from '../assets/event-type-icons/ScheduleEvent.svg';
import {ReactComponent as SearchIcon} from '../assets/event-type-icons/SearchEvent.svg';
import {ReactComponent as StartTrialIcon} from '../assets/event-type-icons/StartTrialEvent.svg';
import {ReactComponent as SubscribeIcon} from '../assets/event-type-icons/SubscribeEvent.svg';
import {ReactComponent as ViewContentIcon} from '../assets/event-type-icons/ViewContentEvent.svg';

// Antd table vertical scroll needs a limit in pixels
// so this helper function fetches currrent window height
function getWindowHeight() {
  const {innerHeight: height} = window;
  return height;
}

export function useWindowHeight() {
  const [windowHeight, setWindowHeight] = useState(getWindowHeight());

  useEffect(() => {
    function handleResize() {
      setWindowHeight(getWindowHeight());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowHeight;
}

export async function checkIfMultiTenant(): Promise<boolean> {
  try {
    let url = '/tenant';
    let res = await fetch(url);
    if (res.ok) {
      let data = await res.json();
      return data.multitenantEnabled;
    } else {
      // Lets consider single tenant as the default
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export function abbreviateNumber(value: number): string {
  let newValue = '';
  if (value >= 1000) {
    let suffixes = ['', 'k', 'm', 'b', 't'];
    let suffixNum = Math.floor(value.toString().length / 3);
    let shortValue = 0;
    for (let precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum !== 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(precision),
      );
      let dotLessShortValue = shortValue
        .toString()
        .replace(/[^a-zA-Z 0-9]+/g, '');
      if (dotLessShortValue.length <= 3) {
        break;
      }
    }
    shortValue = parseFloat(shortValue.toFixed(2));
    newValue = shortValue + suffixes[suffixNum];
  } else {
    newValue = value.toString();
  }
  return newValue;
}

export function relativeTime(current: number, previous: number) {
  let msPerMinute = 60 * 1000;
  let msPerHour = msPerMinute * 60;
  let msPerDay = msPerHour * 24;

  let elapsed = current - previous;
  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + ' seconds ago';
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + ' minutes ago';
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + ' hours ago';
  } else {
    return 'more than 24hs ago';
  }
}

export enum StandardEvents {
  ADDPAYMENTINFO = 'AddPaymentInfo',
  ADDTOCART = 'AddToCart',
  ADDTOWISHLIST = 'AddToWishlist',
  COMPLETEREGISTRATION = 'CompleteRegistration',
  CONTACT = 'Contact',
  FINDLOCATION = 'FindLocation',
  INITIATECHECKOUT = 'InitiateCheckout',
  PURCHASE = 'Purchase',
  SCHEDULE = 'Schedule',
  SEARCH = 'Search',
  STARTTRIAL = 'StartTrial',
  SUBSCRIBE = 'Subscribe',
  VIEWCONTENT = 'ViewContent',
}

export const StandardEventLabels = new Map<string, string>([
  [StandardEvents.ADDPAYMENTINFO, 'Add payment info'],
  [StandardEvents.ADDTOCART, 'Add to cart'],
  [StandardEvents.ADDTOWISHLIST, 'Add to wish list'],
  [StandardEvents.COMPLETEREGISTRATION, 'Complete registration'],
  [StandardEvents.CONTACT, 'Contact'],
  [StandardEvents.FINDLOCATION, 'Find location'],
  [StandardEvents.INITIATECHECKOUT, 'Initiate checkout'],
  [StandardEvents.PURCHASE, 'Purchase'],
  [StandardEvents.SCHEDULE, 'Schedule'],
  [StandardEvents.SEARCH, 'Search'],
  [StandardEvents.STARTTRIAL, 'Start trial'],
  [StandardEvents.SUBSCRIBE, 'Subscribe'],
  [StandardEvents.VIEWCONTENT, 'View content'],
]);

export const StandardEventIcons = new Map<string, typeof PurchaseIcon>([
  [StandardEvents.ADDPAYMENTINFO, AddPaymentInfoIcon],
  [StandardEvents.ADDTOCART, AddToCartIcon],
  [StandardEvents.ADDTOWISHLIST, AddToWishlistIcon],
  [StandardEvents.COMPLETEREGISTRATION, CompleteRegistrationIcon],
  [StandardEvents.CONTACT, ContactIcon],
  [StandardEvents.FINDLOCATION, FindLocationIcon],
  [StandardEvents.INITIATECHECKOUT, InitiateCheckoutIcon],
  [StandardEvents.PURCHASE, PurchaseIcon],
  [StandardEvents.SCHEDULE, ScheduleIcon],
  [StandardEvents.SEARCH, SearchIcon],
  [StandardEvents.STARTTRIAL, StartTrialIcon],
  [StandardEvents.SUBSCRIBE, SubscribeIcon],
  [StandardEvents.VIEWCONTENT, ViewContentIcon],
]);

export function standardEventsMapping(event_name: string) {
  let name = StandardEventLabels.get(event_name) || event_name;
  let Icon = StandardEventIcons.get(event_name) || UnknownEventIcon;
  return {name, Icon};
}

export function pixelHasErrors(
  isActive: boolean,
  apiErrorCode: string | null,
): boolean {
  return isActive && apiErrorCode !== null;
}

// TODO @gribeler consolidate util functions from capig (capig/src/shared/Utils.ts)
//  and hub ui (hub-ui/stc/util/UtilFunction.tsx)
export function notEmpty<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  return value !== null && value !== undefined;
}

export function filterNulls<TValue>(a: Array<TValue | null>): Array<TValue> {
  return a.filter((element) => notEmpty(element)) as Array<TValue>;
}

export function isEmpty<TValue>(a: ReadonlyArray<TValue | null>): boolean {
  return a.length > 0 ? false : true;
}

export function isEventAlwaysFiltered(eventName: string): boolean {
  return eventName === 'Microdata' || eventName === 'SubscribedButtonClick';
}
