import {addValueAtPath} from './utils';

const MULTI_VALUE_DELIMETOR = '|';
const USER_DATA = 'user_data';
const CUSTOM_DATA = 'custom_data';
const APP_DATA = 'app_data';

const FIELDS_SUPPORT_MULTI_VALUE = [
  // TODO: need to find ways to support 'array of objects'.
  // UserData
  `${USER_DATA}.em`,
  `${USER_DATA}.ph`,
  `${USER_DATA}.ge`,
  `${USER_DATA}.ln`,
  `${USER_DATA}.fn`,
  `${USER_DATA}.ct`,
  `${USER_DATA}.st`,
  `${USER_DATA}.zp`,
  `${USER_DATA}.country`,
  `${USER_DATA}.external_id`,
  `${USER_DATA}.db`,
  // CustomData
  `${CUSTOM_DATA}.content_ids`,
  // AppData
  `${APP_DATA}.url_schemes`,
  `${APP_DATA}.extinfo`,
];

const REQUIRED_FIELDS = ['event_name', 'event_time', `${USER_DATA}`];

export function missingRequiredColumns(headers: Array<string>): Array<string> {
  const missingColumns = [] as Array<string>;
  const topLevelKeys = headers.map((column) =>
    column.split('.')[0].toLowerCase(),
  );
  for (const field of REQUIRED_FIELDS) {
    if (!topLevelKeys.includes(field)) {
      missingColumns.push(field);
    }
  }
  return missingColumns;
}

export function buildServerSideEvent(
  headers: Array<string>,
  row: Array<string>,
) {
  // Convert from flat csv to nested json object.
  return headers.reduce((event, header, i) => {
    if (row[i]) {
      if (FIELDS_SUPPORT_MULTI_VALUE.includes(header)) {
        row[i]
          .split(MULTI_VALUE_DELIMETOR)
          .forEach((value) => addValueAtPath(event, header, value));
      } else {
        addValueAtPath(event, header, row[i]);
      }
    }
    return event;
  }, {});
}
