/**
 * @format
 */
import React from 'react';
import ReactDOM from 'react-dom';

import '@cloudbridge/components/index.less';
import {App} from './App';

ReactDOM.render(<App />, document.getElementById('root'));
