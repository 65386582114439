export async function postToServer(
  batch: Array<object>,
  dataSetId: string,
  timeout: number,
  dataType: string,
) {
  if (batch == null || batch.length === 0) {
    return true;
  }
  let data = {
    data: batch,
  };
  let dataStr = JSON.stringify(data);
  let success = true;
  const timeoutHandle = timeoutHandler(timeout);
  await fetch(`/capig/${dataSetId}/upload?data_type=${dataType}`, {
    method: 'POST',
    body: dataStr,
    signal: timeoutHandle.signal,
  })
    .then((response) => (success = response.ok))
    .catch((err) => {
      success = false;
      console.log('Posting to server errored out:', err);
    });
  timeoutHandle.clear();
  return success;
}

function timeoutHandler(time: number) {
  let controller = new AbortController();

  let timeoutId = setTimeout(() => controller.abort(), time);
  return {signal: controller.signal, clear: () => clearTimeout(timeoutId)};
}
