import Papa from 'papaparse';
import {
  buildServerSideEvent,
  missingRequiredColumns,
} from './ServerSideEventBuilder';

export type ParseResult = {
  data?: object;
  isComplete?: boolean;
  isError?: boolean;
};

export type Producer = {
  produce: (v: number) => void;
  cancel: () => void;
};

export function csvDataProducer(
  file: File,
  dataHandle: (data: ParseResult) => void,
  errorHandle: (msg: string) => void,
): Producer {
  let csvParser: Papa.Parser;
  let rowsToProduce = 0;
  const producer: Producer = {
    produce: (toProduce: number) => {
      rowsToProduce += toProduce;
      if (rowsToProduce > 0) {
        if (!csvParser) {
          init();
        }
        csvParser?.resume();
      }
    },
    cancel: () => csvParser?.abort(),
  };
  function init() {
    let headers: Array<string>;
    Papa.parse(file, {
      header: false, //process header manually.
      worker: false,
      skipEmptyLines: 'greedy',
      step: (results, parser) => {
        if (!csvParser) {
          csvParser = parser;
        }
        if (!headers) {
          headers = results.data as Array<string>;
          const missingColumns = missingRequiredColumns(headers);
          if (missingColumns.length !== 0) {
            console.log(
              `${
                file.name
              } parse aborted, missing columns:[${missingColumns.toString()}]`,
            );
            errorHandle('Parse aborted: Malformed CSV');
            parser.abort();
          }
          return;
        }
        let row = results.data as Array<any>;
        if (headers.length === row.length) {
          dataHandle({data: buildServerSideEvent(headers, row)});
          rowsToProduce--;
          if (rowsToProduce <= 0) {
            parser.pause();
          }
        } else {
          dataHandle({isError: true});
        }
      },
      complete: () => {
        dataHandle({isComplete: true});
      },
    });
  }
  return producer;
}
