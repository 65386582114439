/**
 * @format
 */
import React from 'react';
import {Col, Layout, PageHeader, Row} from 'antd';

import styles from './SingleColumnLayout.module.less';

type Props = React.PropsWithChildren<{
  title: React.ReactNode;
}>;

export function SingleColumnLayout({title, children}: Props) {
  return (
    <Layout className={styles.root}>
      <PageHeader className={styles.header} title={title} ghost={false} />
      <Layout.Content className={styles.content}>
        <Row className={styles.content_row} align="middle" justify={'center'}>
          <Col className={styles.content_col}>{children}</Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
}
